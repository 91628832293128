import React, { useEffect } from "react";
import {Alert, Button} from "@mui/material";
import {useAtom} from "jotai";
import {successAtom} from "../../state/State";


export const SuccessComponent: React.FC = () => {

    const [success, setSuccess] = useAtom(successAtom);

    useEffect(() => {
        const timeId = setTimeout(() => {
            setSuccess({success: false, message: ''})
          }, 3000)
      
          return () => {
            clearTimeout(timeId)
          }
    }, [success]);

    return (
        <React.Fragment>
            {success.success && 
                <Alert
                    variant="standard"
                    severity="success"
                    style={{ 
                        position: 'absolute',
                        left: '20px',
                        bottom: '40px',
                        height: '60px',
                        lineHeight: '60px',
                        alignItems: 'center',
                        zIndex: '1201'
                    }}
                >
                    Success! {success.message}
                </Alert>
            }
        </React.Fragment>
    );
}