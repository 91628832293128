import React, {useEffect, useState} from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {PubmedContent, Relevance, ResultsData, TrialsContent} from "../LiteratureSearch/LiteratureSearch";

interface IProps {
    index: number,
    docObject: ResultsData<PubmedContent | TrialsContent>
    relevanceUpdate: Function,
    displayRelevanceButtons: boolean
}

export const RelevanceSwitches: React.FC<IProps> = ({
                                                        index,
                                                        docObject,
                                                        relevanceUpdate,
                                                        displayRelevanceButtons
                                                    }) => {

    const [notRelevant, setNotRelevant] = useState<boolean>(false);
    const [relevant, setRelevant] = useState<boolean>(false);


    // On mount
    useEffect(() => {
        // Code to run on component mount
        // ...
        setRelevant(false);
        setNotRelevant(false);

        return () => {
            // Code to run on component unmount (cleanup)
            // ...
        };
    }, []);
    useEffect(() => {
        if (docObject != null) {
            switch (docObject.relevance) {
                case Relevance.NotRelevant:
                    setNotRelevant(true);
                    setRelevant(false);
                    break;
                case Relevance.Relevant:
                    setRelevant(true);
                    setNotRelevant(false);
                    break;
                case Relevance.Viewed:
                    break;
                case Relevance.NotSet:
                    setRelevant(false);
                    setNotRelevant(false);
                    break;
                default:
                    setRelevant(false);
                    setNotRelevant(false);
            }
        } else {
            setRelevant(false);
            setNotRelevant(false);
        }
    });

    const handleRelevanceUpdate = (switchType: string, clicked: boolean) => {
        switch (switchType) {
            case 'relevant':
                if (!!clicked) {
                    docObject.relevance = Relevance.Relevant;
                } else {
                    docObject.relevance = Relevance.NotSet;
                }
                break;
            case 'notRelevant':
                if (!!clicked) {
                    docObject.relevance = Relevance.NotRelevant;
                } else {
                    docObject.relevance = Relevance.NotSet;
                }
                break;
            default:
                docObject.relevance = Relevance.NotSet;
        }
        relevanceUpdate(index);
    }
    return (
        <React.Fragment>
            <div style={{display: displayRelevanceButtons ? 'block' : 'none'}}>
                <FormControlLabel
                    control={<Checkbox
                        data-testid="trialRel"
                        id="trialRel"
                        checked={relevant}
                        onChange={() => handleRelevanceUpdate('relevant', !relevant)}
                        color="primary"
                        inputProps={{'aria-label': 'checkbox trialRel'}}
                    />}
                    label="Relevant"
                />
                <FormControlLabel
                    control={<Checkbox
                        data-testid="trialNonRel"
                        id="trialNonRel"
                        checked={notRelevant}
                        onChange={() => handleRelevanceUpdate('notRelevant', !notRelevant)}
                        color="primary"
                        inputProps={{'aria-label': 'checkbox trialNonRel'}}
                    />}
                    label="Non-relevant"
                />
            </div>
        </React.Fragment>
    );
}
