import {BrowserRouter, Redirect, Route, Switch} from 'react-router-dom';
import React from 'react';
import './App.css';
import LiteratureSearch from './components/LiteratureSearch/LiteratureSearch';
import LoginPage from "./components/Login/LoginPage";
import Cookies from "js-cookie";
import {ErrorComponent} from "./components/Notifications/ErrorComponent";
import { SuccessComponent } from './components/Notifications/SuccessComponent';


function App() {
    const hasUsername = Cookies.get('loginUsername');
    const hasPassword = Cookies.get('loginPassword');

    return (
        <BrowserRouter>
            <Switch>
                <Route path="/login">
                    {hasUsername && hasPassword ? <Redirect to="/"/> : <LoginPage/>}
                </Route>
                <Route path="/">
                    <ErrorComponent/>
                    <SuccessComponent/>
                    <LiteratureSearch/>
                </Route>
                {/*<Route path="/login" component={LoginPage}/>*/}
                {/* <Route path="/" exact component={LiteratureSearch}> {hasUsername && hasPassword ? <Redirect to="/login" /></>*/}
                {/* <Route exact path="/logout" component={LogoutPage} />*/}
                <Redirect to="/login"/>

            </Switch>
        </BrowserRouter>
    );
}

export default App;
