import * as d3 from "d3";
import { transform } from "lodash";
import { ContextData, createContextMenu } from "./KnowledgeGraphUtils";
import { CentralNode } from "../LiteratureSearch/LiteratureSearch";

export interface NodeData {
  id: number,
  label: string,
  labelSplit: Array<string>,
  labelWidth: number,
  group: string,
  count: number,
  index: number,
  pubmed_ids?: Array<string>,
  trial_ids?: Array<string>, 
  vx?: number,
  vy?: number,
  fx?: number | null,
  fy?: number | null,
  x?: number,
  y?: number,
}

interface DOMBox {
  bottom: number;
  height: number;
  left: number;
  right: number;
  top: number;
  width: number;
  x: number;
  y: number;
}

function RectToBox(rect: DOMRect): DOMBox {
  let box: DOMBox = {
    bottom: rect.bottom,
    height: rect.height,
    left: rect.left,
    right:  rect.right,
    top:  rect.top,
    width:  rect.width,
    x:  rect.x,
    y:  rect.y,
  };

  return box
}

export function runForceGraph(
    container: any,
    links: any,
    nodes: any,
    handleAddTermToSearchValue: Function,
    handleZoomInClick: Function,
    centralNode: CentralNode | '',
    contextData: ContextData
  ) {

  const drag: any = (simulation: any) => {
    const dragstarted: any = (event: any, d: NodeData) => {
      if (!event.active) simulation.alphaTarget(0.3).restart();
      d.fx = d.x;
      d.fy = d.y;
    };

    const dragged: any = (event: any, d: NodeData) => {
      d.fx = event.x;
      d.fy = event.y;
    };

    const dragended: any = (event: any, d: NodeData) => {
      if (!event.active) simulation.alphaTarget(0);
      d.fx = null;
      d.fy = null;
    };

    return d3
      .drag()
      .on("start", dragstarted)
      .on("drag", dragged)
      .on("end", dragended);
  };

  const zoomFunction: any = d3.zoom()
  .scaleExtent([0.70, 2])
  .on("zoom", function (event: any) {
    baseGroup.attr("transform", event.transform);
  });

  d3.selectAll("#graph-svg").remove();

  const svg = d3
    .select(container)
    .append("svg")
    .attr("id", "graph-svg")
    .attr('key', "graph-svg")
    .call(zoomFunction)
    .lower()

  let containerBox: DOMBox = RectToBox(container.getBoundingClientRect());
  let viewBoxDimensions: Array<number> =  [0, 0, containerBox.width, containerBox.height];
  
  function updateWindow(onContextMenu: Boolean = false) {
    let containerBoxNew: DOMBox = RectToBox(container.getBoundingClientRect());
    if (onContextMenu) {
      let heightOffset = containerBox.height - containerBoxNew.height;
      containerBoxNew.top = containerBoxNew.top + heightOffset / 3;
      containerBoxNew.height = containerBox.height;
    }

    containerBox = containerBoxNew;
    viewBoxDimensions =  [0, 0, containerBox.width, containerBox.height];
    if (!onContextMenu) {
      svg.attr("viewBox", viewBoxDimensions.join(' '));
    }
  }


  updateWindow();

  const baseGroup = svg
    .append("g")
    .attr('id', 'nodeGroup');

  d3.select(window).on("resize", updateWindow);

  const link = baseGroup
    .append("g")
    .attr("stroke", "#999")
    .attr("stroke-opacity", 0.6)
    .selectAll("line")
    .data(links)
    .join("line")
    .attr('key', (d: any) => `line-${d.id}`);

  const node = baseGroup.selectAll("g.node")
    .data(nodes)
    .enter()
    .append("g")
    .attr("class", "node")
    .attr('key', (d: any) => `node-${d.id}`)
    .on('contextmenu', (event: any, d: any) => {
      updateWindow(true);
      createContextMenu(
        event, d, containerBox.top, containerBox.left, '#graph-svg', handleAddTermToSearchValue,
        handleZoomInClick, viewBoxDimensions, contextData
    )})

  node.append("rect")
    .attr("stroke", "#999")
    .attr('stroke-width', 1)
    .attr("y", 1)
    .attr("rx", 6)
		.attr("ry", 6)
    .attr("height", 20)
    .attr("class", (d: any) => {
      if (centralNode && d.id === centralNode.id && d.label === centralNode.label) {
        return `node-rect rect-central-node`
      } else {

    return `node-rect rect-${d.group}`}
  })

  node.append("text")
    .attr("x", 0)
    .attr("y", 10)
    .attr('dy', 5)
    .attr("text-anchor", "middle")
    .text((d: any) => d.label)

  node.selectAll('rect')
    .attr("width", function() {
      const element: any = this;
      return element.parentNode.getBBox().width + 20;
    })
    .attr("x", function(d: any) {
      const element: any = this;
      d.labelWidth = element.width.baseVal.value
      return -(d.labelWidth / 2)
    })

  const simulation = d3.forceSimulation(nodes)
    .force("link", d3.forceLink(links).id((d: any) => d.id))
    .force("charge", d3.forceManyBody().strength(nodes.length > 200 ? -300 : -500).distanceMin(60))
    .force("center", d3.forceCenter(containerBox.width / 2, (containerBox.height / 2) - 10))
    .force("x", d3.forceX())
    .force("y", d3.forceY())
    .force('collision', d3.forceCollide().radius(() => nodes.length > 200 ? 20 : 34))

  // run through the first few ticks to reduce the graph bounce slightly
  for (var i = 0; i < 20; ++i) simulation.tick();

  function onTick() {
    updateWindow()
    link
      .attr("x1", (d: any) => boundBox(viewBoxDimensions, d.source.x, 10, 'x'))
      .attr("y1", (d: any) => boundBox(viewBoxDimensions, d.source.y, 10, 'y'))
      .attr("x2", (d: any) => boundBox(viewBoxDimensions, d.target.x, 10, 'x'))
      .attr("y2", (d: any) => boundBox(viewBoxDimensions, d.target.y, 10, 'y'));
    node
      .attr("transform", function (d: any) {
        d.x = boundBox(viewBoxDimensions, d.x, this.getBBox().width / 2, 'x');
        d.y = boundBox(viewBoxDimensions, d.y, this.getBBox().height / 2, 'y');
        return "translate(" + d.x + "," + d.y +")";
      });
  }

  node.call(drag(simulation));
  simulation.on("tick", () => onTick());

  return {
    destroy: () => { simulation.stop() },
    nodes: () => { return svg.node() }
  };
}

function boundBox(viewBox: Array<number>, val: number, dimension: number, axis: string): number {
  if (axis === 'x')
    return Math.max(viewBox[0] + dimension, Math.min(viewBox[2] - dimension, val));
  else
    return Math.max(viewBox[1] + dimension, Math.min(viewBox[3] - dimension - 15, val));
}
