import {buildToken, getBaseUrl} from "../auth/auth";

export async function loadCurrentCase() {
    const res = await fetch(`${getBaseUrl()}/admin/cases/current`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Basic ${buildToken()}`
        }
    });
    if (res.status === 200 || res.status === 201) {

        let jsonCase = await res.json()
        return jsonCase;
    } else {
        return false;
    }
}

export async function loadCurrentCaseSavedDocsList() {
    const res = await fetch(`${getBaseUrl()}/admin/cases/current/_saved_docs`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Basic ${buildToken()}`
        }
    });
    if (res.status === 200 || res.status === 201) {

        let jsonCaseSavedDocs = await res.json()
        return jsonCaseSavedDocs;
    } else {
        return [];
    }
}

export async function updateUsersCaseTitle(newTitle) {
    newTitle = encodeURI(newTitle);
    const res = await fetch(`${getBaseUrl()}/admin/cases/current/${newTitle}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Basic ${buildToken()}`
        }
    });
    if (res.status === 200 || res.status === 201) {
        return true;
    } else {
        return false;
    }
}

export async function createNewCase(caseTitle, caseDescription) {
    caseTitle = encodeURI(caseTitle);
    caseDescription = encodeURI(caseDescription);
    let body = {
        title: caseTitle,
        description: caseDescription
    }
    const res = await fetch(`${getBaseUrl()}/admin/cases`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Basic ${buildToken()}`,
        },
        body: JSON.stringify(body)
    });
    if (res.status === 200 || res.status === 201) {
        let result = {
            error: false,
            case: await res.json(),
            message: ''
        }
        return result
    } else {
        return {
            error: true,
            case: null,
            message: await new Response(res.body).text()
        }
    }
}

export async function loadCases(includeSaved) {
    let uri = `${getBaseUrl()}/admin/cases`;
    if (!includeSaved) {
        uri = `${getBaseUrl()}/admin/cases/_ignoresaved`;
    }

    const res = await fetch(uri, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Basic ${buildToken()}`
        }
    });
    if (res.status === 200 || res.status === 201) {

        //return await res.json()
        let cases = await res.json();
        return cases;

    } else {
        return [];
    }
}

export async function getUsersNames() {
    let uri = `${getBaseUrl()}/admin/users/names`;

    const res = await fetch(uri, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Basic ${buildToken()}`
        }
    });
    if (res.status === 200 || res.status === 201) {
        let users = await res.json();
        return users;

    } else {
        return [];
    }
}

export async function addDocumentToCase(caseTitle, documentData) {
    caseTitle = encodeURI(caseTitle);
    const docData = structuredClone(documentData)
    delete docData['content']

    const res = await fetch(`${getBaseUrl()}/admin/cases/${caseTitle}/_add_docid?docid=${docData["id"]}_`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Basic ${buildToken()}`,
        },
        body: JSON.stringify({"documentData": docData})
    });
    if (res.status === 200 || res.status === 201) {
        let result = {
            error: false,
            caseSavedDocs: await res.json(),
            message: ''
        }
        return result
    } else {
        return {
            error: true,
            caseSavedDocs: null,
            message: "Something went wrong and new case not saved."
        }
    }
}

export async function removeDocumentFromCase(caseTitle, docId) {
    caseTitle = encodeURI(caseTitle);
    const res = await fetch(`${getBaseUrl()}/admin/cases/${caseTitle}/_remove_docid?docid=${docId}_`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Basic ${buildToken()}`,
        }
    });
    if (res.status === 200 || res.status === 201) {
        let result = {
            error: false,
            caseSavedDocs: await res.json(),
            message: ''
        }
        return result
    } else {
        return {
            error: true,
            caseSavedDocs: null,
            message: "Something went wrong and new case not saved."
        }
    }
}

export async function logRelevanceAssessment(query, docId, rank, relevance, ranker) {
    let relevanceLog = {
        query: query,
        doc: docId,
        rank: rank,
        relevance: relevance,
        ranker: ranker
    }
    const res = await fetch(`${getBaseUrl()}/admin/log/relevance`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Basic ${buildToken()}`,
        },
        body: JSON.stringify(relevanceLog)
    });
    if (res.status === 200 || res.status === 201) {
        let result = {
            error: false,
            message: 'OK'
        }
        return result
    } else {
        return {
            error: true,
            message: "Something went wrong logging relevance assessment."
        }
    }
}

/**
 * Find out if document is in list of saved docs.
 * @param document document
 * @param savedData saved document list
 */
export function doesDocIdMatch(document, savedData) {
    for (let savedDoc in savedData) {
        if (document["id"] === savedData[savedDoc].id) {
            return true;
        }
    }
    return false;
}

export async function getCaseDocs() {
    const res = await fetch(`${getBaseUrl()}/admin/cases/current/_saved_docs/ris`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Basic ${buildToken()}`
        }
    });
    if (res.status === 200 || res.status === 201) {

        let ris = await res.blob()
        return ris;
    } else {
        return [];
    }
}

export async function archiveCase(caseTitle) {
    caseTitle = encodeURI(caseTitle);
    const res = await fetch(`${getBaseUrl()}/admin/cases/archive/${caseTitle}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Basic ${buildToken()}`,
        }
    });
    if (res.status === 200 || res.status === 201) {
        let result = {
            error: false,
            message: 'OK',
            res: await res.json()
        }
        return result
    } else {
        return {
            error: true,
            caseSavedDocs: null,
            message: "Something went wrong and case was not archived."
        }
    }
}

export async function shareCase(caseTitle, shared, sharedWith) {
    caseTitle = encodeURI(caseTitle);
    let body = { shared, sharedWith };
    const res = await fetch(`${getBaseUrl()}/admin/cases/share/${caseTitle}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Basic ${buildToken()}`,
        },
        body: JSON.stringify(body)
    });
    if (res.status === 200 || res.status === 201) {
        let result = {
            error: false,
            message: 'OK',
            res: await res.json()
        }
        return result
    } else {
        return {
            error: true,
            caseSavedDocs: null,
            message: "Something went wrong and case was not shared."
        }
    }
}

export async function getMyCaseSurveys() {
    const res = await fetch(`${getBaseUrl()}/admin/surveys`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Basic ${buildToken()}`,
        },
    });
    if (res.status === 200 || res.status === 201) {
        let result = {
            error: false,
            message: 'OK',
            res: await res.json()
        }
        return result
    } else {
        return {
            error: true,
            caseSavedDocs: null,
            message: "Something went wrong and the survey were not retrieved."
        }
    }
}

export async function submitSurvey(caseTitle, surveyResponses) {
    caseTitle = encodeURI(caseTitle);
    let body = { surveyResponses };
    const res = await fetch(`${getBaseUrl()}/admin/surveys/${caseTitle}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Basic ${buildToken()}`,
        },
        body: JSON.stringify(body)
    });
    if (res.status === 200 || res.status === 201) {
        let result = {
            error: false,
            message: 'OK',
            res: await res.json()
        }
        return result
    } else {
        return {
            error: true,
            caseSavedDocs: null,
            message: "Something went wrong and the survey was not saved."
        }
    }
}

export async function getMyOscarSurvey() {
    const res = await fetch(`${getBaseUrl()}/admin/oscar/surveys`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Basic ${buildToken()}`,
        },
    });
    if (res.status === 200 || res.status === 201) {
        let result = {
            error: false,
            message: 'OK',
            res: await res.json()
        }
        return result
    } else {
        return {
            error: true,
            caseSavedDocs: null,
            message: "Something went wrong and the survey were not retrieved."
        }
    }
}

export async function submitOscarSurvey(surveyResponses) {
    let body = { surveyResponses };
    const res = await fetch(`${getBaseUrl()}/admin/oscar/surveys`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Basic ${buildToken()}`,
        },
        body: JSON.stringify(body)
    });
    if (res.status === 200 || res.status === 201) {
        let result = {
            error: false,
            message: 'OK',
            res: await res.json()
        }
        return result
    } else {
        return {
            error: true,
            caseSavedDocs: null,
            message: "Something went wrong and the survey was not saved."
        }
    }
}