import {atomWithStorage} from 'jotai/utils'
import {PubmedContent, ResultsData, TrialsContent} from "../components/LiteratureSearch/LiteratureSearch";
import {atom} from 'jotai'

export const currentUserAtom = atomWithStorage('currentUserAtom', {
    username: "",
    firstname: "",
    surname: "",
    current_case_title: "",
});

export const caseList = atomWithStorage('caseList',
    [{
        title: '',
        description: '',
        saved_docs: {},
        creation_datetime: null,
        readonly: true,
        selected: false,
        created_by: ''
    }]
);

export const caseSavedDocsList = atomWithStorage<ResultsData<PubmedContent | TrialsContent>[]>('caseSavedDocs',
    []
);

export const pageSizeState = atomWithStorage('pageSizeState', 20);

export const errorAtom = atom({error: false, message: ''});

export const successAtom = atom({success: false, message: ''})
