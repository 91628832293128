import { Reference } from '../LiteratureSearch/LiteratureSearch';
import DescriptionIcon from '@material-ui/icons/Description';
import CloseIcon from '@material-ui/icons/Close';
import Popover from '@material-ui/core/Popover';
import {
    Typography,
    IconButton,
    ListItemText,
    ListItemButton,
} from '@mui/material';

interface IReferences {
    references: Array<Reference>,
    anchorRef: any,
    open: boolean,
    handleClick: any,
    handleClose: any,
}

export const ResultReferences: React.FC<IReferences> = ({
    references,
    anchorRef,
    open,
    handleClick,
    handleClose,
}) => {

    const id = open ? 'simple-popover' : undefined;

    return (
        <div className='reference-icon'>
            <IconButton
                aria-label="toggle password visibility"
                onClick={() => handleClick(references.length)}
                edge="start"
                ref={anchorRef}
                classes={{ 'root': 'mui-icon-button' }}
            >
                <DescriptionIcon style={{ color: 'royalblue' }}></DescriptionIcon>
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorRef.current}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                classes={{'root': 'mui-pop-root', 'paper': 'mui-paper'}}
                >
                <Typography classes={{ 'root': 'mui-typo-outer-root' }}>
                    {references.map((value, index) => {
                        const { PMID, citation } = value;

                        return <Typography
                            style={{ padding: '10px' }}
                            key={`typo-${PMID}`}
                            classes={{ 'root': 'mui-typo-root' }}
                            sx={{
                                    bgcolor: 'background.paper',
                                    boxShadow: 2,
                                    borderRadius: 4,
                                    p: 2,
                                    minWidth: 300,
                                    padding: 0,
                                    margin: 2
                            }}>
                                <ListItemButton
                                    style={index % 2 ? {background: "white"} : {background: "white"}}
                                    key={`li-${PMID}`}
                                    component="a"
                                    href={PMID ? `https://pubmed.ncbi.nlm.nih.gov/${PMID}` : ''}
                                    target="_blank"
                                    disabled={PMID ? false : true}
                                >
                                    <ListItemText
                                        className='results-reference-link'
                                        primary={`${PMID ? PMID : ''}`}
                                        secondary={citation}
                                    ></ListItemText>
                                </ListItemButton>
                        </Typography>
                    })}
                </Typography>
                <IconButton
                    aria-label="close popover button"
                    onClick={handleClose}
                    classes={{'root': 'close'}}
                    sx={{ p: 0 }}
                    size='small'
                >
                    <CloseIcon classes={{ root: 'close'}}></CloseIcon>
                </IconButton>
            </Popover>
        </div>
    );
}