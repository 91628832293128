import React, {useState} from 'react';
import clsx from 'clsx';
import {withStyles} from '@material-ui/core/styles';
import Autocomplete, {createFilterOptions} from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import {SelectedNode} from './LiteratureSearch';
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from '@material-ui/icons/Clear';
import IconButton from '@material-ui/core/IconButton';
import Chip from '@material-ui/core/Chip';

interface IProps {
    classes: any;
    selectedNodes: SelectedNode[],
    handleChangeSearchTerms: Function,
    handleSearch: Function;
    handleClearSearchField: Function,
    nodes: any,
}

/*interface IState {
    manuallyAddedNodeOptions: SelectedNode[],
}*/

const styles = () => ({
    textField: {
        width: "60ch",
    },
    margin: {
        margin: "20px",
    },
    outer: {
        display: "flex",
        justifyContent: "center",
        width: "60%",
        margin: "auto",
        marginTop: "10px",
        marginBottom: "20px",
    }
});

const SearchBar: React.FC<IProps> = ({
                                         classes,
                                         selectedNodes,
                                         handleClearSearchField,
                                         handleSearch,
                                         handleChangeSearchTerms,
                                         nodes
                                     }) => {

    const [manuallyAddedNodeOptions, setManuallyAddedNodeOptions] = useState<(string | SelectedNode)[]>([]);
    /*constructor(props: IProps) {
        super(props);
        this.state = {
            manuallyAddedNodeOptions: [],
      }
    }*/

    const handleClearSearchFieldClick = () => {
        handleClearSearchField();
        setManuallyAddedNodeOptions([]);
    }

    const handleClickSearchIcon = () => {
        handleSearch();
    };

    const handleKeyPress = (event: { key: string; }) => {
        if (event.key === 'Enter') {
            handleSearch();
        }
    }

    const handleMouseDownSearch = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const handleAddSearchTerm = (value: (string | SelectedNode)[]) => {
        handleChangeSearchTerms(value);
    }

    const filterOptions = createFilterOptions<SelectedNode>();
    const allOptions = nodes ? nodes.concat(manuallyAddedNodeOptions) : [];

    return (
        <div className={clsx(classes.outer)}>
            <Autocomplete
                onKeyPress={handleKeyPress}
                multiple={true}
                fullWidth
                id="search-autocomplete"
                options={allOptions}
                getOptionLabel={(option: SelectedNode) => option?.label}
                getOptionSelected={(option, value) => option.label === value.label}
                value={selectedNodes}
                autoHighlight={true}
                onChange={(event, value) => {
                    handleAddSearchTerm(value);
                    // @ts-ignore
                    const manuallyAdded = value.filter((node: SelectedNode) => node.id === -1);
                    setManuallyAddedNodeOptions(manuallyAdded)

                }}
                freeSolo={true}
                autoComplete={true}
                filterOptions={(options: SelectedNode[], params) => {
                    let filtered = filterOptions(options, params);
                    if (params.inputValue !== '') {
                        filtered.unshift({label: params.inputValue, count: -1, id: -1, group: ""});
                    }

                    return filtered;
                }}
                disableClearable
                forcePopupIcon={false}
                renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                        <Chip
                            classes={{root: `${option.group}-colour`}}
                            label={option.label}
                            {...getTagProps({index})}
                        />
                    ))
                }
                renderInput={(params) => (
                    <div className="autocomplete-container">
                        <div className="autocomplete-text">
                            <TextField
                                {...params}
                                variant="standard"
                                label="Search"
                            />
                        </div>
                        <div className="autocomplete-buttons">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClearSearchFieldClick}
                                onMouseDown={handleMouseDownSearch}
                                edge="end"
                                disabled={!selectedNodes.length}
                            >
                                <ClearIcon/>
                            </IconButton>
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickSearchIcon}
                                onMouseDown={handleMouseDownSearch}
                                edge="end"
                                disabled={!selectedNodes.length}
                            >
                                <SearchIcon/>
                            </IconButton>
                        </div>
                    </div>
                )}
            />
        </div>
    );

}

export default withStyles(styles)(SearchBar);
