
export const likertMarks_reduced_workload =[{
    value: 1,
    label: 'Significantly reduced workload',
},
{
    value: 2,
    label: 'Reduced workload',
},
{
    value: 3,
    label: 'Somewhat reduced workload',
},
{
    value: 4,
    label: 'Neutral',
},
{
    value: 5,
    label: 'Somewhat increased workload',
},
{
    value: 6,
    label: 'Increased workload',
},
{
    value: 7,
    label: 'Significantly increased workload',
}]

export const likertMarks_perceived_effort = [{
    value: 1,
    label: 'No perceived effort in learning',
},
{
    value: 2,
    label: 'Small amount of effort',
},
{
    value: 3,
    label: 'Some effort in learning',
},
{
    value: 4,
    label: 'Significant effor',
},
{
    value: 5,
    label: 'Not worth the effort',
}
];

export const likertMarks_relevant_treatments = [{
    value: 1,
    label: 'Highly confident',
},
{
    value: 2,
    label: 'Confident',
},
{
    value: 3,
    label: 'Somewhat confident',
},
{
    value: 4,
    label: 'Neutral',
},
{
    value: 5,
    label: 'Somewhat unconfident',
},
{
    value: 6,
    label: 'Unconfident',
},
{
    value: 7,
    label: 'Hightly unconfident',
}];

export const likertMarks_continue_future = [{
    value: 1,
    label: 'Highly likely',
},
{
    value: 2,
    label: 'Likely',
},
{
    value: 3,
    label: 'Somewhat likely',
},
{
    value: 4,
    label: 'Neutral',
},
{
    value: 5,
    label: 'Somewhat unlikely',
},
{
    value: 6,
    label: 'Unlikely',
},
{
    value: 7,
    label: 'Highly unlikely',
}]