import React from "react";
import {Alert, Button} from "@mui/material";
import {useAtom} from "jotai";
import {errorAtom} from "../../state/State";


export const ErrorComponent: React.FC = () => {

    const [error, setError] = useAtom(errorAtom);

    const resetError = () => {
        setError({error: false, message: ''});
    }
    return (
        <React.Fragment>
            {error.error && 
                <Alert
                    variant="standard"
                    severity="error"
                    style={{ position: 'absolute', left: '20px', bottom: '40px',  height: '60px', lineHeight: '60px', alignItems: 'center' }}
                >
                    {error.message}
                    <Button
                        onClick={resetError}
                        variant="outlined"
                        color={error.error ? 'error' : 'info'}
                        style={{
                            margin: '12px 10px 0 30px',
                            float: "right"
                        }}
                    >Clear
                    </Button>
                </Alert>
            }
        </React.Fragment>
    );
}
