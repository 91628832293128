import React from 'react';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Slider from '@material-ui/core/Slider';

interface IProps {
    checkboxes: {
        pubmed: boolean;
        trial: boolean;
        aus: boolean;
    };
    slider: {
        sliderAge: Array<number>;
        sliderPhase: Array<number>;
    };
    handleChangeCheckbox: Function;
    handleChangeSlider: Function;
    searchOrSaved: boolean;
    filterSearchResults: Function;
}

const ageMarks: Array<{ value: number; label: string }> = [
    {
        value: 0,
        label: '<12',
    },
    {
        value: 1,
        label: '12-Adult',
    },
    {
        value: 2,
        label: 'Adult',
    },
];

const phaseMarks: Array<{ value: number; label: string }> = [
    {
        value: 0,
        label: 'Pre-Clinical',
    },
    {
        value: 1,
        label: 'I',
    },
    {
        value: 2,
        label: 'II',
    },
    {
        value: 3,
        label: 'III',
    },
    {
        value: 4,
        label: 'FDA/TGA Approved',
    },
];

const SearchParamsBar: React.FC<IProps> = ({
                                               checkboxes,
                                               slider,
                                               handleChangeCheckbox,
                                               handleChangeSlider,
                                               searchOrSaved,
                                               filterSearchResults,
                                           }) => {
    const handleSliderChange = (event: any, value: number | number[]) => {
        handleChangeSlider({...slider, [event.target.id]: value}, searchOrSaved ? 'search' : 'saved');
    };

    const handleCheckboxChange = (event: any) => {
        handleChangeCheckbox({...checkboxes, [event.target.id]: event.target.checked});
    };

    const handleFilterClick = () => {
        filterSearchResults();
    };

    const {sliderAge, sliderPhase} = slider;
    const {pubmed, trial, aus} = checkboxes;

    return (
        <div className="search-params-bar-grid">
            <div className="checkbox-filters">
                <FormControlLabel
                    control={
                        <Checkbox
                            id="pubmed"
                            checked={pubmed}
                            color="primary"
                            inputProps={{'aria-label': 'checkbox pubmed'}}
                            onChange={handleCheckboxChange}
                        />
                    }
                    label="PubMed"
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            id="trial"
                            checked={trial}
                            color="primary"
                            inputProps={{'aria-label': 'checkbox trials'}}
                            onChange={handleCheckboxChange}
                        />
                    }
                    label="Trials"
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            id="aus"
                            checked={aus}
                            color="primary"
                            inputProps={{'aria-label': 'checkbox trials'}}
                            onChange={handleCheckboxChange}
                        />
                    }
                    label="AU Trials"
                />
            </div>
            <div className={'age-slider'}>
                <Slider
                    id="sliderAge"
                    value={sliderAge}
                    aria-labelledby="age slider"
                    valueLabelDisplay="off"
                    step={1}
                    marks={ageMarks}
                    max={2}
                    onChange={handleSliderChange}
                />
            </div>
            <div className={'phase-slider'}>
                <Slider
                    id="sliderPhase"
                    value={sliderPhase}
                    aria-labelledby="phase slider"
                    valueLabelDisplay="off"
                    step={1}
                    marks={phaseMarks}
                    max={4}
                    onChange={handleSliderChange}
                />
            </div>
            <div className="filter">
                <Button onClick={handleFilterClick} variant="contained" color="primary">
                    FILTER
                </Button>
            </div>
        </div>
    );
};

export default SearchParamsBar;
