import Cookies from "js-cookie";

export function getBaseUrl() {
    let BASE_URL = process.env.REACT_APP_DOCKER_BASE_URL;
    if (process.env.NODE_ENV && process.env.NODE_ENV === 'development') {
        BASE_URL = process.env.REACT_APP_DEV_BASE_URL;
    }
    return BASE_URL;
}

export async function checkAuthed() {
    let BASE_URL = getBaseUrl();
    const username = Cookies.get('loginUsername');
    const password = Cookies.get('loginPassword');
    const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');
    const res = await fetch(`${BASE_URL}/login`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Basic ${token}`
        }
    });
    if (res.status === 401) {
        Cookies.set('loginUsername', '');
        Cookies.set('loginPassword', '');
        return false;
    } else if (res.status === 200 || res.status === 201) {

        let jsonUser = await res.json()
        return jsonUser;
    } else {
        return false;
    }
}

export function buildToken() {
    const username = Cookies.get('loginUsername');
    const password = Cookies.get('loginPassword');
    return Buffer.from(`${username}:${password}`, 'utf8').toString('base64');
}

export function loggUserOut() {
    Cookies.set('loginUsername', '');
    Cookies.set('loginPassword', '');
    window.location.replace("/login")
}
