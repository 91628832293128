import React, {useEffect, useState} from 'react';
import {
    Button,
    Card,
    CardContent,
    CardHeader,
    Checkbox,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Drawer,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemText,
    MenuItem,
    Popover,
    Radio,
    RadioGroup,
    Stack,
    TextField,
} from '@mui/material';
import {Add, HighlightOff, Share} from '@material-ui/icons';
import AssignmentIcon from '@material-ui/icons/Assignment';
import './CaseSwitcher.css';
import {useAtom} from 'jotai';
import {caseList, caseSavedDocsList, currentUserAtom, errorAtom, successAtom} from "../../state/State";
import * as service from "../../service/Service"
import { groupBy } from '../../utilities';
import { Slider, Typography } from '@material-ui/core';
import {
    likertMarks_continue_future,
    likertMarks_perceived_effort,
    likertMarks_reduced_workload,
    likertMarks_relevant_treatments
} from './LikertScales';

interface ICase {
    title: string
    description: string
    saved_docs: {}
    creation_datetime: null
    readonly: boolean
    selected: boolean
    created_by: string
}

interface IUsersNames {
    username: string
    firstname: string
    surname: string
}

interface ICSUsersNames {
    name: string
    username: string
    checked: boolean
}

interface ISurveyResponse {
    case_hours: number
    manual_hours: number
    queries_issued: number
    treatments_int: number
    treatments_str: string
    found_by_osc: string
    missed_by_osc: string
    [key: string]: number | string
}

interface IOscarSurveyResponse {
    reduced_workload: number
    perceived_effort: number
    relevant_treatments: number
    continue_future: number
    [key: string]: number
}

interface IState {
    dialogOpen: any
    caseSharingSelected: string
    caseSharingSelectedValues: ICSUsersNames[]
    usersNames: IUsersNames[]
    surveyDialogOpen: any
    surveyResponses: {[key: string]: ISurveyResponse}
    openedSurveyResponses: {[key: string]: ISurveyResponse}
    oscarSurveyDialogOpen: boolean
    oscarSurveyResponse: IOscarSurveyResponse
    revertableOscarSurveyResponse: IOscarSurveyResponse
}

const CaseSwitcher: React.FC = () => {
    const [, setError] = useAtom(errorAtom);
    const [, setSuccess] = useAtom(successAtom);
    const [caseName, setCaseName] = useState("");
    const [caseDescription, setCaseDescription] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    const [isCreateDrawerOpen, setCreateDrawerIsOpen] = useState(false);
    const [caseListState, setCaseListState] = useAtom(caseList);
    const [currentUser, setCurrentUser] = useAtom(currentUserAtom);
    const [, setCaseSavedDocs] = useAtom(caseSavedDocsList)
    const [selectedCase, setSelectedCase] = React.useState("");
    const [dialogOpen, setDialogOpen] = React.useState<IState['dialogOpen']>([]);
    const [surveyDialogOpen, setSurveyDialogOpen] = React.useState<IState['surveyDialogOpen']>([]);
    const [oscarSurveyDialogOpen, setOscarSurveyDialogOpen] = React.useState<IState['oscarSurveyDialogOpen']>(false);
    const [surveyResponses, setSurveyResponses] = React.useState<IState['surveyResponses']>({});
    const [oscarSurveyResponse, setOscarSurveyResponse] = React.useState<IState['oscarSurveyResponse']>({reduced_workload: 1, perceived_effort: 1, relevant_treatments: 1, continue_future: 1});
    const [revertableSurveyResponses, setRevertableSurveyResponses] = React.useState<IState['openedSurveyResponses']>({});
    const [revertableOscarSurveyResponse, setRevertableOscarSurveyResponse] = React.useState<IState['revertableOscarSurveyResponse']>();
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [caseSharing, setCaseSharing] = React.useState<{[key: string]: IUsersNames}[]>([]);
    const [caseSharingSelected, setCaseSharingSelected] = React.useState<IState['caseSharingSelected']>('');
    const [caseSharingSelectedValues, setCaseSharingSelectedValues] = React.useState<IState['caseSharingSelectedValues']>([])
    const [usersNames, setUsersNames] = React.useState<IState['usersNames']>([]);

    const handlePopoverClose = () => {
        setAnchorEl(null);
        setCaseSharingSelected('');
        setCaseSharingSelectedValues([]);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    // componentDidMount
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        service.loadCases(false).then((cases) => {
            setCaseListState([...cases]);
            const dOpen = cases.reduce((result: any, c: any) => { result[c.title] = false; return result; }, {});
            setDialogOpen(dOpen);
            setSurveyDialogOpen(structuredClone(dOpen));
            const sr = cases.filter((c: ICase) => c.created_by === currentUser.username)
                .reduce((result: any, c: ICase) => { result[c.title] = {}; return result; }, {})
            service.getMyCaseSurveys().then((ok: any) => {
                setSurveyResponses(ok.res.reduce((result: any, el: any) => { result[el.case_title] = el; return result; }, sr));
            });
        });
        setSelectedCase(currentUser.current_case_title);

        service.getMyOscarSurvey().then((ok: any) => {
            if (Object.hasOwn(ok.res, 'username')) setOscarSurveyResponse(ok.res);
        });

        service.getUsersNames().then((usersNames: IUsersNames[]) => {
            setUsersNames(usersNames);
        });
    }, [currentUser]);

    useEffect(() => {
        let caseShare: any = {}
        caseListState.forEach((el: any) => {
            const clinicians: ICSUsersNames[] = usersNames.map((user) => { return { username: user.username, checked: el.shared_with && el.shared_with.includes(user.username), name: `${user.firstname} ${user.surname}`} });
            caseShare = { ...caseShare, [el.title]: sortByName(clinicians)}
        });
        setCaseSharing(caseShare);
    }, [usersNames]);

    const toggleDrawer = () => {
        setIsOpen(!isOpen);
    };

    const toggleCreateDrawer = () => {
        setCreateDrawerIsOpen(!isCreateDrawerOpen);
        setCaseName('');
        setCaseDescription('');
    };

    const saveNewCase = () => {
        service.createNewCase(caseName, caseDescription).then((result) => {
            if (!result.error) {
                setCurrentUser({...currentUser, current_case_title: caseName});
                service.updateUsersCaseTitle(caseName).then((ok) => {
                    if (ok) {
                        console.log('::::: about to load cases (saveNewCase')
                        service.loadCases(false).then((cases) => {
                            setCaseListState([...cases]);
                            service.loadCurrentCaseSavedDocsList().then((data) => {
                                setCaseSavedDocs([...data]);
                            });
                        });
                    }
                });
            } else {
                setError({error: result.error, message: result.message});
            }
        }).catch((error) => {
            setError({error: true, message: String(error)});
        });
        toggleCreateDrawer();
        toggleDrawer();
    }

    const handleRadioChange = (e: any) => {
        setSelectedCase(e.target.value);
        service.updateUsersCaseTitle(e.target.value).then((ok) => {
            if (ok) {
                setCurrentUser({...currentUser, current_case_title: e.target.value});
                service.loadCurrentCaseSavedDocsList().then((data) => {
                    setCaseSavedDocs([...data]);
                });
                service.loadCases(false).then((caseList) => {
                    setCaseListState([...caseList]);
                });
            } else {
                setError({error: true, message: 'Unable to update current case.'})
            }
        }).catch((error) => {
            setError({error: true, message: 'Unable to update current case. ' + String(error)})
        });
    }

    const handleClickDelete = (caseTitle: string) => {
        const updated = structuredClone(dialogOpen);
        updated[caseTitle] = true;
        setDialogOpen(updated);
    };

    const sortByName = (csArr: ICSUsersNames[]) => {
        return csArr.sort((a: any, b: any) => {return (a.name > b.name ? 1 : (a.name === b.name ? 0 : -1))});
    }

    const handleClickShare = (event: React.MouseEvent<HTMLButtonElement>, csc: any, caseKey: string) => {
        setCaseSharingSelected(caseKey);
        setCaseSharingSelectedValues(csc);
        setAnchorEl(event.currentTarget);
    }

    const handleSelectShareChange = (caseKey: string, username: string, index: number) => {
        const cs: any = structuredClone(caseSharing);
        service.shareCase(caseKey, !cs[caseKey][index].checked, username).then((ok: any) => {
            if (ok) {
                cs[caseKey] = ok.res.user_sharing;
                const sortedUsers = sortByName(cs[caseKey]);
                setCaseSharingSelectedValues(sortedUsers);
                const updatedCaseSharing = { ...caseSharing, [caseKey]: cs[caseKey]};
                setCaseSharing(updatedCaseSharing);
                setSuccess({success: true, message: ok.res.message});
            } else {
                setError({error: true, message: 'Unable to share case. Please contact your administrator.'})
            }
        });
    };

    const handleDialogClose = (caseTitle: string) => {
        const updated = structuredClone(dialogOpen);
        updated[caseTitle] = false;
        setDialogOpen(updated);
    };

    const handleClickConfirm = (caseTitle: string) => {
        service.archiveCase(caseTitle).then((ok: any) => {
            if (ok) {
                const activeCases: ICase[] = ok.res.active_cases ? ok.res.active_cases : [];
                setCaseListState([...activeCases]);
                const updated = structuredClone(dialogOpen);
                updated[caseTitle] = false;
                setDialogOpen(updated);
                setSuccess({success: true, message: ok.res.message});
                // check if archived case was the selected case
                if (ok.res.archived_case === selectedCase) {
                    if (activeCases.findIndex((c: ICase) => {return c.title === 'Explore' }) > -1) {
                        // reset to default case, if exists
                        setSelectedCase('Explore');
                        setCurrentUser({...currentUser, current_case_title: 'Explore'});
                    } else if (activeCases.length > 0) {
                        // if Explore doesn't exist for some reason, set to first case
                        setSelectedCase(activeCases[0].title);
                        setCurrentUser({...currentUser, current_case_title: activeCases[0].title});
                    }
                }
            } else {
                setError({error: true, message: 'Unable to archive case.'})
            }
        }).catch((error) => {
            setError({error: true, message: 'Unable to archive case. ' + String(error)})
        });
    }

    const handleSurveyOpen = (caseTitle: string) => {
        let updated = structuredClone(surveyDialogOpen);
        updated[caseTitle] = true;
        setSurveyDialogOpen(updated);
        setRevertableSurveyResponses({[caseTitle]: surveyResponses[caseTitle]})
    }

    const handleSurveyDialogCancel = (caseTitle: string) => {
        const updated = structuredClone(surveyDialogOpen);
        updated[caseTitle] = false;
        setSurveyDialogOpen(updated);
        const updatedSR = structuredClone(surveyResponses);
        updatedSR[caseTitle] = revertableSurveyResponses[caseTitle];
        setSurveyResponses(updatedSR);
    }

    const handleSurveyValueChange = (caseTitle: string, key: string, value: number | string) => {
        const updated = structuredClone(surveyResponses);
        updated[caseTitle][key] = value;
        setSurveyResponses(updated);
    }

    const handleSurveySubmit = (caseTitle: string) => {
        service.submitSurvey(caseTitle, surveyResponses[caseTitle]).then((ok: any) => {
            if (ok) {
                let updated = structuredClone(surveyResponses);
                ok.res.forEach((el: any) => {
                    updated[el.case_title] = el;
                });
                setSurveyResponses(updated);
                let updatedSDO = structuredClone(surveyDialogOpen);
                updatedSDO[caseTitle] = false;
                setSurveyDialogOpen(updatedSDO);
                setSuccess({success: true, message: `${caseTitle} survey updated`});
            }
        })
    }

    const handleOscarSurveyOpen = () => {
        setRevertableOscarSurveyResponse(structuredClone(oscarSurveyResponse));
        setOscarSurveyDialogOpen(true);
    }

    const handleOscarSurveyCancel = () => {
        setOscarSurveyDialogOpen(false);
        if (revertableOscarSurveyResponse) setOscarSurveyResponse(revertableOscarSurveyResponse);
    }

    const handleOscarSurveyValueChange = (key: string, value: number) => {
        const updated: any = structuredClone(oscarSurveyResponse);
        updated[key] = value;
        setOscarSurveyResponse(updated);
    }

    const handleOscarSurveySubmit = () => {
        service.submitOscarSurvey(oscarSurveyResponse).then((ok: any) => {
            if (ok) {
                setOscarSurveyResponse(ok.res);
                setOscarSurveyDialogOpen(false);
                setSuccess({success: true, message: 'Oscar survey updated'})
            }
        });
    }

    const caseSavedDocsGrouped: any = caseListState.length > 0 ? groupBy(caseListState, ({ created_by }: any) => created_by as any) : {};
    const otherCases = structuredClone(caseSavedDocsGrouped);
    delete otherCases[currentUser.username];
    delete otherCases['oscaradmin'];
    let myCases = caseSavedDocsGrouped['oscaradmin'];
    if (currentUser.username != 'oscaradmin')
        myCases = caseSavedDocsGrouped[currentUser.username] ? myCases?.concat(caseSavedDocsGrouped[currentUser.username]) : myCases;

    return (
        <div>
            <Button onClick={toggleDrawer} variant="outlined" style={{
                margin: '10px'
            }}><em>Case:</em>&nbsp;<b>{currentUser.current_case_title}</b></Button>
            <Drawer
                anchor="left"
                open={isOpen}
                onClose={toggleDrawer}
            >
                <Card
                    sx={{
                        bgcolor: 'background.paper',
                        boxShadow: 2,
                        borderRadius: 0,
                        p: 2,
                        minWidth: 300,
                        padding: 0,
                        margin: 2
                    }}
                    className={isCreateDrawerOpen ? 'new-case-card' : ''}
                >
                    <CardHeader
                        action={
                            <IconButton sx={{
                                justifyContent: "flex-end",
                                alignItems: "right",
                            }}
                            onClick={toggleCreateDrawer}><Add/></IconButton>
                        }
                        titleTypographyProps={{align: "left", variant: "subtitle1"}}
                        title="Create new case"
                        className='create-new-case-header'
                    />
                    <CardContent style={{display: isCreateDrawerOpen ? 'block' : 'none'}}>
                        <Container style={{padding: 0}}>
                            <Grid container spacing={1}
                                    direction="row"
                                    xs={12}
                                    item={true}
                            >
                                <Grid xs={12} item={true}>
                                    <TextField
                                        className='case-input'
                                        label="Case Name"
                                        variant="outlined"
                                        margin="none"
                                        size="medium"
                                        fullWidth
                                        value={caseName}
                                        autoFocus
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            setCaseName(event.target.value);
                                        }}
                                        autoComplete='off'
                                    />
                                </Grid>
                                <Grid xs={12} item={true}>
                                    <TextField
                                        className='case-input'
                                        label="Description"
                                        variant="outlined"
                                        margin="none"
                                        fullWidth
                                        multiline
                                        minRows={1}
                                        maxRows={1/3}
                                        value={caseDescription}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            setCaseDescription(event.target.value);
                                        }}
                                    />
                                </Grid>
                                <Stack direction="row" spacing={2} display="flex" justifyContent="flex-end" width="-webkit-fill-available">
                                <Button variant="outlined" style={{
                                            margin: '10px',
                                        }} onClick={toggleCreateDrawer}
                                        >
                                            Cancel
                                        </Button>
                                        <Button 
                                            variant="outlined"
                                            style={{
                                                margin: '10px 0',
                                            }}
                                            onClick={saveNewCase}
                                        >
                                            Save
                                        </Button>
                                </Stack>
                            </Grid>
                        </Container>
                    </CardContent>
                </Card>
                <List dense sx={{width: '100%', minWidth: 400, maxWidth: 560, bgcolor: 'background.paper', overflowY: 'auto', height: "100vh"}}>
                {myCases && myCases.length > 0 &&
                    <React.Fragment>
                        <Typography variant="overline" className='cases-group-title'>My Cases</Typography>
                        <CasesList 
                            handleRadioChange={handleRadioChange}
                            selectedCase={selectedCase}
                            caseList={myCases}
                            caseSharing={caseSharing}
                            handleClickDelete={handleClickDelete}
                            handleClickShare={handleClickShare}
                            currentUserName={currentUser.username}
                            popoverId={id}
                            popoverOpen={open}
                            anchorEl={anchorEl}
                            handlePopoverClose={handlePopoverClose}
                            caseSharingSelectedValues={caseSharingSelectedValues}
                            caseSharingSelected={caseSharingSelected}
                            handleSelectShareChange={handleSelectShareChange}
                            dialogOpen={dialogOpen}
                            handleDialogClose={handleDialogClose}
                            handleClickConfirm={handleClickConfirm}
                            surveyDialogOpen={surveyDialogOpen}
                            handleSurveyOpen={handleSurveyOpen}
                            handleSurveyDialogCancel={handleSurveyDialogCancel}
                            surveyResponses={surveyResponses}
                            handleSurveyValueChange={handleSurveyValueChange}
                            handleSurveySubmit={handleSurveySubmit}
                        />
                    </React.Fragment>
                }
                {otherCases && Object.keys(otherCases).length > 0 && Object.keys(otherCases).map((caseKey: string) => {
                    
                    return <React.Fragment key={caseKey}>
                        <Typography variant="overline" className='cases-group-title'>Shared by {caseKey}</Typography>
                        <CasesList 
                            handleRadioChange={handleRadioChange}
                            selectedCase={selectedCase}
                            caseList={otherCases[caseKey]}
                            caseSharing={caseSharing}
                            handleClickDelete={handleClickDelete}
                            handleClickShare={handleClickShare}
                            currentUserName={currentUser.username}
                            popoverId={id}
                            popoverOpen={open}
                            anchorEl={anchorEl}
                            handlePopoverClose={handlePopoverClose}
                            caseSharingSelectedValues={caseSharingSelectedValues}
                            caseSharingSelected={caseSharingSelected}
                            handleSelectShareChange={handleSelectShareChange}
                            dialogOpen={dialogOpen}
                            handleDialogClose={handleDialogClose}
                            handleClickConfirm={handleClickConfirm}
                            surveyDialogOpen={surveyDialogOpen}
                            handleSurveyOpen={handleSurveyOpen}
                            handleSurveyDialogCancel={handleSurveyDialogCancel}
                            surveyResponses={surveyResponses}
                            handleSurveyValueChange={handleSurveyValueChange}
                            handleSurveySubmit={handleSurveySubmit}
                            key={caseKey}
                        />
                    </React.Fragment>
                })}
                <div className='oscar-survey'>
                    <Typography variant="overline" className='cases-group-title'>OSCAR Survey</Typography>
                    <IconButton
                            onClick={() => handleOscarSurveyOpen()}
                            aria-label="survey"
                        >
                            <AssignmentIcon />
                    </IconButton>
                </div>
                <Dialog
                    open={oscarSurveyDialogOpen}
                    sx={{ '& .MuiBackdrop-root': { backgroundColor: 'transparent' }}}
                    PaperProps={{style: {minWidth: '900px'}}}
                >
                    <DialogTitle id="survey-dialog-title">{'OSCAR Survey'}</DialogTitle>
                    <div className='survey-questions-likert'>
                        <DialogContentText className='survey-question-likert'>Did you feel OSCAR reduced your workload? (Where workload can mean time taken, perceived effort or cognitive load.)</DialogContentText>
                        <Slider
                            value={oscarSurveyResponse?.reduced_workload}
                            step={1}
                            marks={likertMarks_reduced_workload}
                            min={1}
                            max={7}
                            classes={{markLabel: 'mark-label'}}
                            track={false}
                            onChange={(event: any, value: any) => handleOscarSurveyValueChange('reduced_workload', value)}
                            className='survey-slider'
                        /> 
                        <DialogContentText className='survey-question-likert'>What was your perceived effort in learning how to use OSCAR?</DialogContentText>
                        <Slider
                            value={oscarSurveyResponse?.perceived_effort}
                            step={1}
                            marks={likertMarks_perceived_effort}
                            min={1}
                            max={5}
                            classes={{markLabel: 'mark-label'}}
                            track={false}
                            onChange={(event: any, value: any) => handleOscarSurveyValueChange('perceived_effort', value)}
                            className='survey-slider'
                        />
                        <DialogContentText className='survey-question-likert'>After using OSCAR, how confident are you that relevant treatments have been found (vs manually)?</DialogContentText>
                        <Slider
                            value={oscarSurveyResponse?.relevant_treatments}
                            step={1}
                            marks={likertMarks_relevant_treatments}
                            min={1}
                            max={7}
                            classes={{markLabel: 'mark-label'}}
                            track={false}
                            onChange={(event: any, value: any) => handleOscarSurveyValueChange('relevant_treatments', value)}
                            className='survey-slider'
                        />
                        <DialogContentText className='survey-question-likert'>How likely would you be to continue to use OSCAR for future cases?</DialogContentText>
                        <Slider
                            value={oscarSurveyResponse?.continue_future}
                            step={1}
                            marks={likertMarks_continue_future}
                            min={1}
                            max={7}
                            classes={{markLabel: 'mark-label'}}
                            track={false}
                            onChange={(event: any, value: any) => handleOscarSurveyValueChange('continue_future', value)}
                            className='survey-slider'
                        /> 
                    </div>
                    <DialogActions sx={{margin: '40px'}}>
                        <Button onClick={() => handleOscarSurveyCancel()} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={() => handleOscarSurveySubmit()} color="primary" autoFocus>
                            Submit
                        </Button>
                        </DialogActions>
                </Dialog>
                </List>
            </Drawer>
        </div>
    );
};

export default CaseSwitcher;

interface IProps {
    handleRadioChange: Function,
    selectedCase: string,
    caseList: ICase[],
    caseSharing: {[key: string]: IUsersNames}[],
    handleClickDelete: Function,
    handleClickShare: Function,
    currentUserName: string,
    popoverId: string | undefined,
    popoverOpen: boolean,
    anchorEl: HTMLButtonElement | null,
    handlePopoverClose: any,
    caseSharingSelectedValues: ICSUsersNames[],
    caseSharingSelected: string,
    handleSelectShareChange: Function,
    dialogOpen: any,
    handleDialogClose: any,
    handleClickConfirm: Function,
    surveyDialogOpen: any,
    handleSurveyOpen: Function,
    handleSurveyDialogCancel: Function,
    surveyResponses: {[key: string]: ISurveyResponse},
    handleSurveyValueChange: Function,
    handleSurveySubmit: Function,
}


const CasesList:  React.FC<IProps> = ({
    handleRadioChange,
    selectedCase,
    caseList,
    caseSharing,
    handleClickDelete,
    handleClickShare,
    currentUserName,
    popoverId,
    popoverOpen,
    anchorEl,
    handlePopoverClose,
    caseSharingSelectedValues,
    caseSharingSelected,
    handleSelectShareChange,
    dialogOpen,
    handleDialogClose,
    handleClickConfirm,
    surveyDialogOpen,
    handleSurveyOpen,
    handleSurveyDialogCancel,
    surveyResponses,
    handleSurveyValueChange,
    handleSurveySubmit,
}) => {
    return (
        <FormControl className='case-switcher-form-group'>
            <RadioGroup value={selectedCase} onChange={(event: any) => handleRadioChange(event)} row={true}>
                {caseList.map((caseValue, index) => {
                    let caseSharingCase: any = structuredClone(caseSharing);
                    return <Card key={caseValue.title} sx={{
                        bgcolor: 'background.paper',
                        boxShadow: 2,
                        borderRadius: 4,
                        p: 2,
                        minWidth: '-webkit-fill-available',
                        padding: 0,
                        margin: 2,
                    }}><ListItem style={{background: "white", minHeight: '80px', maxWidth: '400px'}}
                            key={caseValue.title}
                            secondaryAction={
                                <FormControlLabel value={caseValue.title} control={<Radio />} label="" sx={{ marginRight: '0px !important'}}/>
                        }
                    >
                        <ListItemText primary={`${caseValue.title}`} secondary={`${caseValue.description}`} style={{wordBreak: 'break-word'}}></ListItemText>
                        <IconButton
                            onClick={() => handleSurveyOpen(caseValue.title)}
                            aria-label="survey"
                            disabled={!(caseValue.created_by === currentUserName)}
                            style={{marginRight: '-8px'}}
                        >
                            {caseValue.created_by === currentUserName && <AssignmentIcon />}
                        </IconButton>
                        <IconButton
                            onClick={() => handleClickDelete(caseValue.title)}
                            aria-label="delete"
                            disabled={!(caseValue.created_by === currentUserName)}
                            style={{marginRight: '-8px'}}
                        >
                            {caseValue.created_by === currentUserName && <HighlightOff />}
                        </IconButton>
                        <IconButton
                            onClick={(event) => handleClickShare(event, caseSharingCase[caseValue.title], caseValue.title)}
                            aria-label="share"
                            disabled={!(caseValue.created_by === currentUserName)}
                        >
                            {caseValue.created_by === currentUserName && <Share fontSize='small' />}
                        </IconButton>
                    </ListItem>
                    <Popover
                        id={popoverId}
                        open={popoverOpen}
                        anchorEl={anchorEl}
                        onClose={handlePopoverClose}
                        anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
                        style={{minWidth: '120px !important', minHeight: '300px !important'}}
                    >
                        <FormControl className='select-share-form-control'>
                            {caseSharingSelectedValues.map((csc: any, cscIndex: number) => (
                                    <MenuItem key={csc.username} value={csc.name}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                id={csc.username}
                                                checked={csc.checked ? true : false}
                                                color="primary"
                                                inputProps={{'aria-label': 'checkbox'}}
                                                onChange={() => handleSelectShareChange(caseSharingSelected, csc.username, cscIndex)}
                                            />
                                        }
                                        label=""
                                    />
                                    <ListItemText primary={csc.name} />
                                    </MenuItem>
                            ))}
                        </FormControl>
                    </Popover>
                    <Dialog
                        open={dialogOpen[caseValue.title] ? dialogOpen[caseValue.title] : false}
                        onClose={handleDialogClose}
                        sx={{ '& .MuiBackdrop-root': { backgroundColor: 'transparent' }}}
                        PaperProps={{style: {minWidth: '400px'}}}
                    >
                        <DialogTitle id="alert-dialog-title">{`Archive '${caseValue.title}' case?`}</DialogTitle>
                        <DialogContent>
                        <DialogContentText id="alert-dialog-description" style={{wordBreak: 'break-word'}}>
                            {caseValue.description}
                        </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                        <Button onClick={() => handleDialogClose(caseValue.title)} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={() => handleClickConfirm(caseValue.title)} color="primary" autoFocus>
                            Confirm
                        </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog
                        open={surveyDialogOpen[caseValue.title] ? surveyDialogOpen[caseValue.title] : false}
                        sx={{ '& .MuiBackdrop-root': { backgroundColor: 'transparent' }}}
                        PaperProps={{style: {minWidth: '800px'}}}
                        onClose={(e, reason) => { if (reason === 'backdropClick' || reason === 'escapeKeyDown') handleSurveyDialogCancel(caseValue.title)}}
                    >
                        <DialogTitle id="survey-dialog-title">{`'${caseValue.title}' Survey`}</DialogTitle>
                        <div className='survey-questions-quantitative'>
                            <DialogContentText className='survey-question'>How long (hours) did it take to do this case using OSCAR?</DialogContentText>
                            <TextField
                                id="case_hours"
                                className='survey-textfield'
                                type="number"
                                variant="outlined"
                                onChange={(event: any) => handleSurveyValueChange(caseValue.title, event.target.id, event.target.value)}
                                value={surveyResponses[caseValue.title] && surveyResponses[caseValue.title].case_hours ? surveyResponses[caseValue.title].case_hours : ''}
                            />
                            <DialogContentText className='survey-question'>How long did it take with the manual method?</DialogContentText>
                            <TextField
                                id="manual_hours"
                                className='survey-textfield'
                                type="number"
                                variant="outlined"
                                onChange={(event: any) => handleSurveyValueChange(caseValue.title, event.target.id, event.target.value)}
                                value={surveyResponses[caseValue.title] && surveyResponses[caseValue.title].manual_hours ? surveyResponses[caseValue.title].manual_hours : ''}
                            />
                            <DialogContentText className='survey-question'>How many queries did you have to issue in manual system?</DialogContentText>
                            <TextField
                                id="queries_issued"
                                className='survey-textfield'
                                type="number"
                                variant="outlined"
                                onChange={(event: any) => handleSurveyValueChange(caseValue.title, event.target.id, event.target.value)}
                                value={surveyResponses[caseValue.title] && surveyResponses[caseValue.title].queries_issued ? surveyResponses[caseValue.title].queries_issued : ''}
                            />
                            <DialogContentText className='survey-question'>Were there new and relevant treatments found using OSCAR were not found with the manual method? How many? What were they?</DialogContentText>
                            <TextField
                                id="treatments_int"
                                className='survey-textfield'
                                type="number"
                                variant="outlined"
                                onChange={(event: any) => handleSurveyValueChange(caseValue.title, event.target.id, event.target.value)}
                                value={surveyResponses[caseValue.title] && surveyResponses[caseValue.title].treatments_int ? surveyResponses[caseValue.title].treatments_int : ''}
                            />
                        </div>
                        <div className='survey-questions-qualitative'>
                            <TextField
                                id="treatments_str"
                                multiline
                                rows={2}
                                variant="outlined"
                                onChange={(event: any) => handleSurveyValueChange(caseValue.title, event.target.id, event.target.value)}
                                value={surveyResponses[caseValue.title] && surveyResponses[caseValue.title].treatments_str ? surveyResponses[caseValue.title].treatments_str : ''}
                            />
                            <DialogContentText className='survey-question'>Can you please explain how they were found or why you think the manual method missed them?</DialogContentText>
                            <TextField
                                id="found_by_osc"
                                multiline
                                rows={2}
                                variant="outlined"
                                onChange={(event: any) => handleSurveyValueChange(caseValue.title, event.target.id, event.target.value)}
                                value={surveyResponses[caseValue.title] && surveyResponses[caseValue.title].found_by_osc ? surveyResponses[caseValue.title].found_by_osc : ''}
                            />
                            <DialogContentText className='survey-question'>Were there relevant treatments missed by OSCAR. (How did they find these additional publications: ie which website/search platform? What query did they use?)</DialogContentText>
                            <TextField
                                id="missed_by_osc"
                                multiline
                                rows={2}
                                variant="outlined"
                                onChange={(event: any) => handleSurveyValueChange(caseValue.title, event.target.id, event.target.value)}
                                value={surveyResponses[caseValue.title] && surveyResponses[caseValue.title].missed_by_osc ? surveyResponses[caseValue.title].missed_by_osc : ''}
                            />
                        </div>
                        <DialogActions sx={{margin: '40px'}}>
                        <Button onClick={() => handleSurveyDialogCancel(caseValue.title)} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={() => handleSurveySubmit(caseValue.title)} color="primary" autoFocus>
                            Submit
                        </Button>
                        </DialogActions>
                    </Dialog>
                    </Card>
                })}
            </RadioGroup>
        </FormControl>
    )
}
