import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import Cookies from 'js-cookie';
import * as auth from "../../auth/auth";
import "./LoginPage.css";
import {useAtom} from 'jotai';
import {currentUserAtom} from "../../state/State";
import { Box, Button, TextField, Typography } from '@material-ui/core';


const LoginPage: React.FC = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const history = useHistory();

    const [userState, setUserState] = useAtom(currentUserAtom);


    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();

        console.log("::::::::::::User name ", username);

        // Store username and password in a cookie
        Cookies.set('loginUsername', username);
        Cookies.set('loginPassword', password);
        auth.checkAuthed().then((authed) => {
            if (!authed) {
                window.location.replace("/login")
            } else {

                // Clear the form fields
                setUsername('');
                setPassword('');

                // save the user data?
                console.log(':::::::;---> User ', authed)
                setUserState({
                    ...userState, username: authed.username,
                    firstname: authed.firstname, surname: authed.surname, current_case_title: authed.current_case_title
                });
                history.push('/');
            }
        });
    };

    // Check if credentials are already present
    /*
    const hasUsername = Cookies.get('loginUsername');
    const hasPassword = Cookies.get('loginPassword');

    if (hasUsername && hasPassword) {
        return <Redirect to="/"/>;
    }*/

    return (
        <div className="login-wrapper">
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Typography component="h1" variant="h4" style={{margin: '20px'}}>
                    OSCAR
                </Typography>
                <Typography component="h1" variant="h6">
                    Oncology Search Clinician Assistant Registry
                </Typography>
                {/* <Avatar style={{marginTop:'80px'}}>
                    <LockIcon color='primary'/>
                </Avatar> */}
                <Box component="form" onSubmit={handleSubmit} style={{marginTop:'40px'}}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Username"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        onChange={(e) => setUsername(e.target.value)}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color='primary'
                        style={{marginTop: '20px'}}
                    >
                    Sign In
                    </Button>
                </Box>
            </Box>
        </div>
    );
};

export default LoginPage;
